export const getWAOData = () => {
    return [
        {
          "Category": "Both Sexes Total",
          "Total Number": 324356,
          "Total Percent": 100,
          "Asian alone Number": 19790,
          "Asian alone Percent": 100,
          "Non-Hispanic White Number": 195060,
          "Non-Hispanic White Percent": 100,
          "Other Number": 109506,
          "Other Percent": 100,
          "Sex": "Both"
        },
        {
          "Category": "Under 5 years",
          "Total Number": 19736,
          "Total Percent": 6.1,
          "Asian alone Number": 1083,
          "Asian alone Percent": 5.5,
          "Non-Hispanic White Number": 9605,
          "Non-Hispanic White Percent": 4.9,
          "Other Number": 9048,
          "Other Percent": 8.3,
          "Sex": "Both"
        },
        {
          "Category": "5 to 9 years",
          "Total Number": 20212,
          "Total Percent": 6.2,
          "Asian alone Number": 1094,
          "Asian alone Percent": 5.5,
          "Non-Hispanic White Number": 9990,
          "Non-Hispanic White Percent": 5.1,
          "Other Number": 9128,
          "Other Percent": 8.3,
          "Sex": "Both"
        },
        {
          "Category": "10 to 14 years",
          "Total Number": 20827,
          "Total Percent": 6.4,
          "Asian alone Number": 1113,
          "Asian alone Percent": 5.6,
          "Non-Hispanic White Number": 10525,
          "Non-Hispanic White Percent": 5.4,
          "Other Number": 9189,
          "Other Percent": 8.4,
          "Sex": "Both"
        },
        {
          "Category": "15 to 19 years",
          "Total Number": 20849,
          "Total Percent": 6.4,
          "Asian alone Number": 1136,
          "Asian alone Percent": 5.7,
          "Non-Hispanic White Number": 10957,
          "Non-Hispanic White Percent": 5.6,
          "Other Number": 8756,
          "Other Percent": 8,
          "Sex": "Both"
        },
        {
          "Category": "20 to 24 years",
          "Total Number": 21254,
          "Total Percent": 6.6,
          "Asian alone Number": 1410,
          "Asian alone Percent": 7.1,
          "Non-Hispanic White Number": 11425,
          "Non-Hispanic White Percent": 5.9,
          "Other Number": 8419,
          "Other Percent": 7.7,
          "Sex": "Both"
        },
        {
          "Category": "25 to 29 years",
          "Total Number": 23277,
          "Total Percent": 7.2,
          "Asian alone Number": 1696,
          "Asian alone Percent": 8.6,
          "Non-Hispanic White Number": 12558,
          "Non-Hispanic White Percent": 6.4,
          "Other Number": 9023,
          "Other Percent": 8.2,
          "Sex": "Both"
        },
        {
          "Category": "30 to 34 years",
          "Total Number": 21932,
          "Total Percent": 6.8,
          "Asian alone Number": 1694,
          "Asian alone Percent": 8.6,
          "Non-Hispanic White Number": 12333,
          "Non-Hispanic White Percent": 6.3,
          "Other Number": 7905,
          "Other Percent": 7.2,
          "Sex": "Both"
        },
        {
          "Category": "35 to 44 years",
          "Total Number": 41027,
          "Total Percent": 12.6,
          "Asian alone Number": 3173,
          "Asian alone Percent": 16,
          "Non-Hispanic White Number": 23274,
          "Non-Hispanic White Percent": 11.9,
          "Other Number": 14580,
          "Other Percent": 13.3,
          "Sex": "Both"
        },
        {
          "Category": "45 to 54 years",
          "Total Number": 40700,
          "Total Percent": 12.5,
          "Asian alone Number": 2723,
          "Asian alone Percent": 13.8,
          "Non-Hispanic White Number": 25119,
          "Non-Hispanic White Percent": 12.9,
          "Other Number": 12858,
          "Other Percent": 11.7,
          "Sex": "Both"
        },
        {
          "Category": "55 to 64 years",
          "Total Number": 41755,
          "Total Percent": 12.9,
          "Asian alone Number": 2190,
          "Asian alone Percent": 11.1,
          "Non-Hispanic White Number": 29056,
          "Non-Hispanic White Percent": 14.9,
          "Other Number": 10509,
          "Other Percent": 9.6,
          "Sex": "Both"
        },
        {
          "Category": "65 to 74 years",
          "Total Number": 31487,
          "Total Percent": 9.7,
          "Asian alone Number": 1546,
          "Asian alone Percent": 7.8,
          "Non-Hispanic White Number": 23542,
          "Non-Hispanic White Percent": 12.1,
          "Other Number": 6399,
          "Other Percent": 5.8,
          "Sex": "Both"
        },
        {
          "Category": "75 to 84 years",
          "Total Number": 15408,
          "Total Percent": 4.8,
          "Asian alone Number": 690,
          "Asian alone Percent": 3.5,
          "Non-Hispanic White Number": 11985,
          "Non-Hispanic White Percent": 6.1,
          "Other Number": 2733,
          "Other Percent": 2.5,
          "Sex": "Both"
        },
        {
          "Category": "85 years and over",
          "Total Number": 5893,
          "Total Percent": 1.8,
          "Asian alone Number": 243,
          "Asian alone Percent": 1.2,
          "Non-Hispanic White Number": 4690,
          "Non-Hispanic White Percent": 2.4,
          "Other Number": 960,
          "Other Percent": 0.9,
          "Sex": "Both"
        },
        {
          "Category": "Under 15 years",
          "Total Number": 60774,
          "Total Percent": 18.7,
          "Asian alone Number": 3290,
          "Asian alone Percent": 16.6,
          "Non-Hispanic White Number": 30120,
          "Non-Hispanic White Percent": 15.4,
          "Other Number": 27364,
          "Other Percent": 25,
          "Sex": "Both"
        },
        {
          "Category": "15 years and over",
          "Total Number": 263581,
          "Total Percent": 81.3,
          "Asian alone Number": 16500,
          "Asian alone Percent": 83.4,
          "Non-Hispanic White Number": 164939,
          "Non-Hispanic White Percent": 84.6,
          "Other Number": 82142,
          "Other Percent": 75,
          "Sex": "Both"
        },
        {
          "Category": "Under 16 years",
          "Total Number": 64907,
          "Total Percent": 20,
          "Asian alone Number": 3498,
          "Asian alone Percent": 17.7,
          "Non-Hispanic White Number": 32281,
          "Non-Hispanic White Percent": 16.5,
          "Other Number": 29129,
          "Other Percent": 26.6,
          "Sex": "Both"
        },
        {
          "Category": "16 years and over",
          "Total Number": 259449,
          "Total Percent": 80,
          "Asian alone Number": 16292,
          "Asian alone Percent": 82.3,
          "Non-Hispanic White Number": 162779,
          "Non-Hispanic White Percent": 83.5,
          "Other Number": 80378,
          "Other Percent": 73.4,
          "Sex": "Both"
        },
        {
          "Category": "Under 18 years",
          "Total Number": 73793,
          "Total Percent": 22.8,
          "Asian alone Number": 4019,
          "Asian alone Percent": 20.3,
          "Non-Hispanic White Number": 36863,
          "Non-Hispanic White Percent": 18.9,
          "Other Number": 32911,
          "Other Percent": 30.1,
          "Sex": "Both"
        },
        {
          "Category": "18 years and over",
          "Total Number": 250563,
          "Total Percent": 77.2,
          "Asian alone Number": 15770,
          "Asian alone Percent": 79.7,
          "Non-Hispanic White Number": 158197,
          "Non-Hispanic White Percent": 81.1,
          "Other Number": 76596,
          "Other Percent": 69.9,
          "Sex": "Both"
        },
        {
          "Category": "Under 21 years",
          "Total Number": 85644,
          "Total Percent": 26.4,
          "Asian alone Number": 4659,
          "Asian alone Percent": 23.5,
          "Non-Hispanic White Number": 43145,
          "Non-Hispanic White Percent": 22.1,
          "Other Number": 37840,
          "Other Percent": 34.6,
          "Sex": "Both"
        },
        {
          "Category": "21 years and over",
          "Total Number": 238712,
          "Total Percent": 73.6,
          "Asian alone Number": 15130,
          "Asian alone Percent": 76.5,
          "Non-Hispanic White Number": 151915,
          "Non-Hispanic White Percent": 77.9,
          "Other Number": 71667,
          "Other Percent": 65.4,
          "Sex": "Both"
        },
        {
          "Category": "Under 65 years",
          "Total Number": 271568,
          "Total Percent": 83.7,
          "Asian alone Number": 17311,
          "Asian alone Percent": 87.5,
          "Non-Hispanic White Number": 154842,
          "Non-Hispanic White Percent": 79.4,
          "Other Number": 99415,
          "Other Percent": 90.8,
          "Sex": "Both"
        },
        {
          "Category": "65 years and over",
          "Total Number": 52788,
          "Total Percent": 16.3,
          "Asian alone Number": 2479,
          "Asian alone Percent": 12.5,
          "Non-Hispanic White Number": 40218,
          "Non-Hispanic White Percent": 20.6,
          "Other Number": 10091,
          "Other Percent": 9.2,
          "Sex": "Both"
        },
        {
          "Category": "Male Total",
          "Total Number": 159028,
          "Total Percent": 100,
          "Asian alone Number": 9493,
          "Asian alone Percent": 100,
          "Non-Hispanic White Number": 96053,
          "Non-Hispanic White Percent": 100,
          "Other Number": 53482,
          "Other Percent": 100,
          "Sex": "Male"
        },
        {
          "Category": "Under 5 years",
          "Total Number": 10094,
          "Total Percent": 6.3,
          "Asian alone Number": 555,
          "Asian alone Percent": 5.8,
          "Non-Hispanic White Number": 4924,
          "Non-Hispanic White Percent": 5.1,
          "Other Number": 4615,
          "Other Percent": 8.6,
          "Sex": "Male"
        },
        {
          "Category": "5 to 9 years",
          "Total Number": 10328,
          "Total Percent": 6.5,
          "Asian alone Number": 548,
          "Asian alone Percent": 5.8,
          "Non-Hispanic White Number": 5133,
          "Non-Hispanic White Percent": 5.3,
          "Other Number": 4647,
          "Other Percent": 8.7,
          "Sex": "Male"
        },
        {
          "Category": "10 to 14 years",
          "Total Number": 10650,
          "Total Percent": 6.7,
          "Asian alone Number": 575,
          "Asian alone Percent": 6.1,
          "Non-Hispanic White Number": 5385,
          "Non-Hispanic White Percent": 5.6,
          "Other Number": 4689,
          "Other Percent": 8.8,
          "Sex": "Male"
        },
        {
          "Category": "15 to 19 years",
          "Total Number": 10545,
          "Total Percent": 6.6,
          "Asian alone Number": 565,
          "Asian alone Percent": 5.9,
          "Non-Hispanic White Number": 5592,
          "Non-Hispanic White Percent": 5.8,
          "Other Number": 4389,
          "Other Percent": 8.2,
          "Sex": "Male"
        },
        {
          "Category": "20 to 24 years",
          "Total Number": 10716,
          "Total Percent": 6.7,
          "Asian alone Number": 717,
          "Asian alone Percent": 7.6,
          "Non-Hispanic White Number": 5817,
          "Non-Hispanic White Percent": 6.1,
          "Other Number": 4182,
          "Other Percent": 7.8,
          "Sex": "Male"
        },
        {
          "Category": "25 to 29 years",
          "Total Number": 11792,
          "Total Percent": 7.4,
          "Asian alone Number": 851,
          "Asian alone Percent": 9,
          "Non-Hispanic White Number": 6380,
          "Non-Hispanic White Percent": 6.6,
          "Other Number": 4561,
          "Other Percent": 8.5,
          "Sex": "Male"
        },
        {
          "Category": "30 to 34 years",
          "Total Number": 10935,
          "Total Percent": 6.9,
          "Asian alone Number": 825,
          "Asian alone Percent": 8.7,
          "Non-Hispanic White Number": 6209,
          "Non-Hispanic White Percent": 6.5,
          "Other Number": 3901,
          "Other Percent": 7.3,
          "Sex": "Male"
        },
        {
          "Category": "35 to 44 years",
          "Total Number": 20257,
          "Total Percent": 12.7,
          "Asian alone Number": 1498,
          "Asian alone Percent": 15.8,
          "Non-Hispanic White Number": 11657,
          "Non-Hispanic White Percent": 12.1,
          "Other Number": 7102,
          "Other Percent": 13.3,
          "Sex": "Male"
        },
        {
          "Category": "45 to 54 years",
          "Total Number": 19923,
          "Total Percent": 12.5,
          "Asian alone Number": 1286,
          "Asian alone Percent": 13.5,
          "Non-Hispanic White Number": 12405,
          "Non-Hispanic White Percent": 12.9,
          "Other Number": 6233,
          "Other Percent": 11.7,
          "Sex": "Male"
        },
        {
          "Category": "55 to 64 years",
          "Total Number": 19865,
          "Total Percent": 12.5,
          "Asian alone Number": 1000,
          "Asian alone Percent": 10.5,
          "Non-Hispanic White Number": 14013,
          "Non-Hispanic White Percent": 14.6,
          "Other Number": 4852,
          "Other Percent": 9.1,
          "Sex": "Male"
        },
        {
          "Category": "65 to 74 years",
          "Total Number": 14889,
          "Total Percent": 9.4,
          "Asian alone Number": 675,
          "Asian alone Percent": 7.1,
          "Non-Hispanic White Number": 11398,
          "Non-Hispanic White Percent": 11.9,
          "Other Number": 2817,
          "Other Percent": 5.3,
          "Sex": "Male"
        },
        {
          "Category": "75 to 84 years",
          "Total Number": 6752,
          "Total Percent": 4.2,
          "Asian alone Number": 316,
          "Asian alone Percent": 3.3,
          "Non-Hispanic White Number": 5324,
          "Non-Hispanic White Percent": 5.5,
          "Other Number": 1112,
          "Other Percent": 2.1,
          "Sex": "Male"
        },
        {
          "Category": "85 years and over",
          "Total Number": 2282,
          "Total Percent": 1.4,
          "Asian alone Number": 82,
          "Asian alone Percent": 0.9,
          "Non-Hispanic White Number": 1816,
          "Non-Hispanic White Percent": 1.9,
          "Other Number": 384,
          "Other Percent": 0.7,
          "Sex": "Male"
        },
        {
          "Category": "Under 15 years",
          "Total Number": 31072,
          "Total Percent": 19.5,
          "Asian alone Number": 1678,
          "Asian alone Percent": 17.7,
          "Non-Hispanic White Number": 15442,
          "Non-Hispanic White Percent": 16.1,
          "Other Number": 13951,
          "Other Percent": 26.1,
          "Sex": "Male"
        },
        {
          "Category": "15 years and over",
          "Total Number": 127956,
          "Total Percent": 80.5,
          "Asian alone Number": 7814,
          "Asian alone Percent": 82.3,
          "Non-Hispanic White Number": 80611,
          "Non-Hispanic White Percent": 83.9,
          "Other Number": 39531,
          "Other Percent": 73.9,
          "Sex": "Male"
        },
        {
          "Category": "Under 16 years",
          "Total Number": 33151,
          "Total Percent": 20.8,
          "Asian alone Number": 1777,
          "Asian alone Percent": 18.7,
          "Non-Hispanic White Number": 16547,
          "Non-Hispanic White Percent": 17.2,
          "Other Number": 14827,
          "Other Percent": 27.7,
          "Sex": "Male"
        },
        {
          "Category": "16 years and over",
          "Total Number": 125877,
          "Total Percent": 79.2,
          "Asian alone Number": 7715,
          "Asian alone Percent": 81.3,
          "Non-Hispanic White Number": 79506,
          "Non-Hispanic White Percent": 82.8,
          "Other Number": 38655,
          "Other Percent": 72.3,
          "Sex": "Male"
        },
        {
          "Category": "Under 18 years",
          "Total Number": 37727,
          "Total Percent": 23.7,
          "Asian alone Number": 2037,
          "Asian alone Percent": 21.5,
          "Non-Hispanic White Number": 18932,
          "Non-Hispanic White Percent": 19.7,
          "Other Number": 16758,
          "Other Percent": 31.3,
          "Sex": "Male"
        },
        {
          "Category": "18 years and over",
          "Total Number": 121301,
          "Total Percent": 76.3,
          "Asian alone Number": 7456,
          "Asian alone Percent": 78.5,
          "Non-Hispanic White Number": 77121,
          "Non-Hispanic White Percent": 80.3,
          "Other Number": 36724,
          "Other Percent": 68.7,
          "Sex": "Male"
        },
        {
          "Category": "Under 21 years",
          "Total Number": 43658,
          "Total Percent": 27.5,
          "Asian alone Number": 2361,
          "Asian alone Percent": 24.9,
          "Non-Hispanic White Number": 22119,
          "Non-Hispanic White Percent": 23,
          "Other Number": 19178,
          "Other Percent": 35.9,
          "Sex": "Male"
        },
        {
          "Category": "21 years and over",
          "Total Number": 115370,
          "Total Percent": 72.5,
          "Asian alone Number": 7132,
          "Asian alone Percent": 75.1,
          "Non-Hispanic White Number": 73934,
          "Non-Hispanic White Percent": 77,
          "Other Number": 34305,
          "Other Percent": 64.1,
          "Sex": "Male"
        },
        {
          "Category": "Under 65 years",
          "Total Number": 135105,
          "Total Percent": 85,
          "Asian alone Number": 8419,
          "Asian alone Percent": 88.7,
          "Non-Hispanic White Number": 77515,
          "Non-Hispanic White Percent": 80.7,
          "Other Number": 49170,
          "Other Percent": 91.9,
          "Sex": "Male"
        },
        {
          "Category": "65 years and over",
          "Total Number": 23923,
          "Total Percent": 15,
          "Asian alone Number": 1073,
          "Asian alone Percent": 11.3,
          "Non-Hispanic White Number": 18538,
          "Non-Hispanic White Percent": 19.3,
          "Other Number": 4312,
          "Other Percent": 8.1,
          "Sex": "Male"
        },
        {
          "Category": "Female Total",
          "Total Number": 165328,
          "Total Percent": 100,
          "Asian alone Number": 10297,
          "Asian alone Percent": 100,
          "Non-Hispanic White Number": 99007,
          "Non-Hispanic White Percent": 100,
          "Other Number": 56024,
          "Other Percent": 100,
          "Sex": "Female"
        },
        {
          "Category": "Under 5 years",
          "Total Number": 9642,
          "Total Percent": 5.8,
          "Asian alone Number": 528,
          "Asian alone Percent": 5.1,
          "Non-Hispanic White Number": 4681,
          "Non-Hispanic White Percent": 4.7,
          "Other Number": 4432,
          "Other Percent": 7.9,
          "Sex": "Female"
        },
        {
          "Category": "5 to 9 years",
          "Total Number": 9884,
          "Total Percent": 6,
          "Asian alone Number": 546,
          "Asian alone Percent": 5.3,
          "Non-Hispanic White Number": 4858,
          "Non-Hispanic White Percent": 4.9,
          "Other Number": 4480,
          "Other Percent": 8,
          "Sex": "Female"
        },
        {
          "Category": "10 to 14 years",
          "Total Number": 10177,
          "Total Percent": 6.2,
          "Asian alone Number": 538,
          "Asian alone Percent": 5.2,
          "Non-Hispanic White Number": 5139,
          "Non-Hispanic White Percent": 5.2,
          "Other Number": 4500,
          "Other Percent": 8,
          "Sex": "Female"
        },
        {
          "Category": "15 to 19 years",
          "Total Number": 10304,
          "Total Percent": 6.2,
          "Asian alone Number": 571,
          "Asian alone Percent": 5.5,
          "Non-Hispanic White Number": 5365,
          "Non-Hispanic White Percent": 5.4,
          "Other Number": 4367,
          "Other Percent": 7.8,
          "Sex": "Female"
        },
        {
          "Category": "20 to 24 years",
          "Total Number": 10538,
          "Total Percent": 6.4,
          "Asian alone Number": 692,
          "Asian alone Percent": 6.7,
          "Non-Hispanic White Number": 5608,
          "Non-Hispanic White Percent": 5.7,
          "Other Number": 4238,
          "Other Percent": 7.6,
          "Sex": "Female"
        },
        {
          "Category": "25 to 29 years",
          "Total Number": 11485,
          "Total Percent": 6.9,
          "Asian alone Number": 845,
          "Asian alone Percent": 8.2,
          "Non-Hispanic White Number": 6177,
          "Non-Hispanic White Percent": 6.2,
          "Other Number": 4463,
          "Other Percent": 8,
          "Sex": "Female"
        },
        {
          "Category": "30 to 34 years",
          "Total Number": 10997,
          "Total Percent": 6.7,
          "Asian alone Number": 869,
          "Asian alone Percent": 8.4,
          "Non-Hispanic White Number": 6124,
          "Non-Hispanic White Percent": 6.2,
          "Other Number": 4004,
          "Other Percent": 7.1,
          "Sex": "Female"
        },
        {
          "Category": "35 to 44 years",
          "Total Number": 20770,
          "Total Percent": 12.6,
          "Asian alone Number": 1675,
          "Asian alone Percent": 16.3,
          "Non-Hispanic White Number": 11617,
          "Non-Hispanic White Percent": 11.7,
          "Other Number": 7478,
          "Other Percent": 13.3,
          "Sex": "Female"
        },
        {
          "Category": "45 to 54 years",
          "Total Number": 20776,
          "Total Percent": 12.6,
          "Asian alone Number": 1438,
          "Asian alone Percent": 14,
          "Non-Hispanic White Number": 12714,
          "Non-Hispanic White Percent": 12.8,
          "Other Number": 6625,
          "Other Percent": 11.8,
          "Sex": "Female"
        },
        {
          "Category": "55 to 64 years",
          "Total Number": 21890,
          "Total Percent": 13.2,
          "Asian alone Number": 1191,
          "Asian alone Percent": 11.6,
          "Non-Hispanic White Number": 15043,
          "Non-Hispanic White Percent": 15.2,
          "Other Number": 5657,
          "Other Percent": 10.1,
          "Sex": "Female"
        },
        {
          "Category": "65 to 74 years",
          "Total Number": 16598,
          "Total Percent": 10,
          "Asian alone Number": 871,
          "Asian alone Percent": 8.5,
          "Non-Hispanic White Number": 12144,
          "Non-Hispanic White Percent": 12.3,
          "Other Number": 3582,
          "Other Percent": 6.4,
          "Sex": "Female"
        },
        {
          "Category": "75 to 84 years",
          "Total Number": 8656,
          "Total Percent": 5.2,
          "Asian alone Number": 373,
          "Asian alone Percent": 3.6,
          "Non-Hispanic White Number": 6662,
          "Non-Hispanic White Percent": 6.7,
          "Other Number": 1620,
          "Other Percent": 2.9,
          "Sex": "Female"
        },
        {
          "Category": "85 years and over",
          "Total Number": 3611,
          "Total Percent": 2.2,
          "Asian alone Number": 161,
          "Asian alone Percent": 1.6,
          "Non-Hispanic White Number": 2874,
          "Non-Hispanic White Percent": 2.9,
          "Other Number": 576,
          "Other Percent": 1,
          "Sex": "Female"
        },
        {
          "Category": "Under 15 years",
          "Total Number": 29703,
          "Total Percent": 18,
          "Asian alone Number": 1611,
          "Asian alone Percent": 15.6,
          "Non-Hispanic White Number": 14678,
          "Non-Hispanic White Percent": 14.8,
          "Other Number": 13413,
          "Other Percent": 23.9,
          "Sex": "Female"
        },
        {
          "Category": "15 years and over",
          "Total Number": 135625,
          "Total Percent": 82,
          "Asian alone Number": 8686,
          "Asian alone Percent": 84.4,
          "Non-Hispanic White Number": 84328,
          "Non-Hispanic White Percent": 85.2,
          "Other Number": 42611,
          "Other Percent": 76.1,
          "Sex": "Female"
        },
        {
          "Category": "Under 16 years",
          "Total Number": 31756,
          "Total Percent": 19.2,
          "Asian alone Number": 1720,
          "Asian alone Percent": 16.7,
          "Non-Hispanic White Number": 15734,
          "Non-Hispanic White Percent": 15.9,
          "Other Number": 14301,
          "Other Percent": 25.5,
          "Sex": "Female"
        },
        {
          "Category": "16 years and over",
          "Total Number": 133572,
          "Total Percent": 80.8,
          "Asian alone Number": 8577,
          "Asian alone Percent": 83.3,
          "Non-Hispanic White Number": 83273,
          "Non-Hispanic White Percent": 84.1,
          "Other Number": 41723,
          "Other Percent": 74.5,
          "Sex": "Female"
        },
        {
          "Category": "Under 18 years",
          "Total Number": 36066,
          "Total Percent": 21.8,
          "Asian alone Number": 1982,
          "Asian alone Percent": 19.3,
          "Non-Hispanic White Number": 17931,
          "Non-Hispanic White Percent": 18.1,
          "Other Number": 16152,
          "Other Percent": 28.8,
          "Sex": "Female"
        },
        {
          "Category": "18 years and over",
          "Total Number": 129262,
          "Total Percent": 78.2,
          "Asian alone Number": 8315,
          "Asian alone Percent": 80.7,
          "Non-Hispanic White Number": 81076,
          "Non-Hispanic White Percent": 81.9,
          "Other Number": 39872,
          "Other Percent": 71.2,
          "Sex": "Female"
        },
        {
          "Category": "Under 21 years",
          "Total Number": 41986,
          "Total Percent": 25.4,
          "Asian alone Number": 2298,
          "Asian alone Percent": 22.3,
          "Non-Hispanic White Number": 21025,
          "Non-Hispanic White Percent": 21.2,
          "Other Number": 18662,
          "Other Percent": 33.3,
          "Sex": "Female"
        },
        {
          "Category": "21 years and over",
          "Total Number": 123342,
          "Total Percent": 74.6,
          "Asian alone Number": 7999,
          "Asian alone Percent": 77.7,
          "Non-Hispanic White Number": 77982,
          "Non-Hispanic White Percent": 78.8,
          "Other Number": 37362,
          "Other Percent": 66.7,
          "Sex": "Female"
        },
        {
          "Category": "Under 65 years",
          "Total Number": 136463,
          "Total Percent": 82.5,
          "Asian alone Number": 8892,
          "Asian alone Percent": 86.4,
          "Non-Hispanic White Number": 77327,
          "Non-Hispanic White Percent": 78.1,
          "Other Number": 50245,
          "Other Percent": 89.7,
          "Sex": "Female"
        },
        {
          "Category": "65 years and over",
          "Total Number": 28865,
          "Total Percent": 17.5,
          "Asian alone Number": 1405,
          "Asian alone Percent": 13.6,
          "Non-Hispanic White Number": 21680,
          "Non-Hispanic White Percent": 21.9,
          "Other Number": 5779,
          "Other Percent": 10.3,
          "Sex": "Female"
        }
      ]
}