export const colorCombos = () => {
    return [
        "#00876c",
        "#439981",
        "#6aaa96",
        "#8cbcac",
        "#aecdc2",
        "#cfdfd9",
        "#f1f1f1",
        "#f1d4d4",
        "#f0b8b8",
        "#ec9c9d",
        "#e67f83",
        "#de6069",
  ]
}