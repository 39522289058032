import React from 'react';
import './App.css';

import { SimpleTabs } from './components/SimpleTabs/SimpleTabs'


function App() {  
  return (
    <div className="App">
        <SimpleTabs />
    </div>
  )
  
}

export default App;
